/* src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Custom styles */
html.dark {
    --bg-color: #1a202c;
    --text-color: #f7fafc;
}

html.light {
    --bg-color: #f7fafc;
    --text-color: #1a202c;
}

body {
    background-color: var(--bg-color);
    color: var(--text-color);
}

html.dark body {
    --bg-color: #1a202c;
    --text-color: #f7fafc;
}

html.light body {
    --bg-color: #f7fafc;
    --text-color: #1a202c;
}

html.dark .bg-white {
    background-color: #1d2635;
    color: #f7fafc;
}

html.dark main {
    background-color: #2d3748;
}

html.dark label.text-gray-700 {
    color: #f7fafc;
}

@keyframes progress {
    0% { width: 0%; }
    100% { width: 100%; }
}

.animate-progress {
    animation: progress 1.5s ease-in-out forwards;
}


.modal-content {
    position: relative;
    background-color: #fff;
    border-radius: 0.5rem;
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
    padding: 2rem;
    max-width: 600px;
    width: 100%;
}

.input-title {
    padding: 0.5rem;
    border: 1px solid #ddd;
    border-radius: 0.25rem;
    width: 100%;
}

.textarea-description {
    padding: 0.5rem;
    border: 1px solid #ddd;
    border-radius: 0.25rem;
    width: 100%;
    height: 8rem;
}

.btn-save {
    background-color: #3490dc;
    color: white;
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
}

.btn-close {
    background-color: #e2e8f0;
    color: #2d3748;
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
}
